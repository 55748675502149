import React from "react";
import { IoMdMenu } from "react-icons/io";
import styled from "styled-components";
import { colors } from "../../../../../../../vars/palette";

const StyledHamburger = styled.button`
  font-size: 1.5rem;
  span {
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 0 0.5vh;
  }
  display: flex;
  align-items: center;
  -webkit-flex: initial;
  background: none;
  appearance: none;
  border: none;
  padding: 1.5vh;
  :hover {
    color: ${colors.hex("primary")};
  }
  @media only screen {
    :focus {
      outline: none;
    }
  }
`;

const MainBurgerButton = ({ toggle }) => {
  return (
    <StyledHamburger onClick={toggle}>
      <IoMdMenu /> <span>Menu</span>
    </StyledHamburger>
  );
};

export default MainBurgerButton;
