import React from "react";
import { WMKLink } from "wmk-lib";
import { Row, Col } from "react-bootstrap";

const UsefulLinks = ({ links }) => {
  return (
    <>
      <strong>Useful Links</strong>
      <Row className="flex-column">
        {links && links.length
          ? links.map((link, i) => {
              const { to, target, text } = link;
              return (
                <Col key={text + i}>
                  <WMKLink to={to} target={target}>
                    {text}
                  </WMKLink>
                </Col>
              );
            })
          : null}
      </Row>
    </>
  );
};

export default UsefulLinks;
