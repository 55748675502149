import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { WMKLink } from "wmk-lib";
import { colors } from "../../../../../vars/palette";

const Styled = {};
Styled.Link = styled(Col)`

background-color: ${({ color }) => (color ? color : colors.hex("secondary"))};
height: auto;
margin: 0 .5vh;
button, .wmk-link {
  text-align: center;
    display: block;
  box-shadow: inset 0 0 3px rgba(0,0,0,.15);
  color: white;
  text-transform: uppercase:
  font-size: .8rem;
  appearance: none;
  border: none;
  height: auto;
  //padding: .25vh .3vh;
  padding: 0 1.5rem;
  //margin: 0;
  &:hover {
    text-decoration: none;
  }
    display: flex;
    width: 100%;
    background-color: ${({ color }) => (color ? color : colors.hex("secondary"))};
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
  
  &:hover {
      text-decoration: none;
      background-color: ${colors.hex("accent")};
      color: white;
      ransition: all .3s ease;
  }
}
`;

const PortalButton = ({ to, target, label, title, altTitle, color }) => {
  return (
    <Styled.Link color={color}>
      <WMKLink to={to} target={target} label={label}>
        <span
          style={{
            fontSize: ".6875rem",
            textTransform: "uppercase",
            lineHeight: 1,
          }}
        >
          <span className="d-block d-lg-none">{altTitle}</span>
          <span className="d-none d-lg-block">{title}</span>
        </span>
      </WMKLink>
    </Styled.Link>
  );
};

export default PortalButton;

/*
          <PortalButton
            to="https://www.ntmwd.com/login/"
            label="Member Portal"
            target="blank"
            title="Member Portal"
            altTitle="Members"
          />
          */
