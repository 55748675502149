import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import PortalTopBar from "./PortalTopBar";

const Styled = {};
Styled.Header = styled(Container)`
  position: relative;
`;

export const PortalHeader = () => {
  return (
    <Styled.Header fluid style={{ padding: 0 }}>
      <PortalTopBar />
    </Styled.Header>
  );
};
