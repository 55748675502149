import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { HeaderLogo } from "../../HeaderLogo";
import Theme from "../../../../../../vars/ThemeOptions";
import PortalButton from "../PortalButton";
import TagLine from "./TagLine";
import { colors } from "../../../../../../vars/palette";

const Styled = {};
Styled.TopBar = styled(Row)`
  background-color: ${colors.hex("primary")};
  box-shadow: 2px 3px 6px ${colors.rgba("black", 0.25)};
  color: ${colors.hex("white")};
  .col {
    padding: 0;
  }
  .tagline {
    font-size: 0.8rem;
    @media screen and (max-width: ${Theme.break.md}px) {
      span {
        display: none;
      }
    }
  }
`;
// Styled.Logo = styled(Col)`
//   position: relative;
// `;
Styled.Link = styled(Col)`
&.alt {
  background-color: ${colors.hex("secondary")};
  button, .wmk-link {
    background-color: ${colors.hex("secondary")};
  }
}
background-color: ${colors.hex("tertiary")};
height: auto;
margin: 0 .5vh;
button, .wmk-link {
  box-shadow: inset 0 0 3px ${colors.rgba("black", 0.15)};
  color: white;
  text-transform: uppercase:
  font-size: .8rem;
  appearance: none;
  border: none;
  height: auto;
  padding: .25vh .3vh;
  &:hover {
    text-decoration: none;
  }
    display: flex;
    width: 100%;
    background: ${colors.hex("tertiary")};
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
  
  &:hover {
      text-decoration: none;
      background-color: ${colors.hex("accent")};
      color: white;
      ransition: all .3s ease;
  }
}
`;

const TopBar = () => {
  return (
    <Styled.TopBar noGutters>
      <Container>
        <Row style={{ minHeight: "32px" }}>
          {/* <Styled.Logo xs={4} sm={3} md={2}> */}

          {/* </Styled.Logo> */}
          <Col sm={3} style={{ padding: 0 }}>
            <HeaderLogo />
          </Col>
          <TagLine
            main="Regional Service Through Unity"
            extra="...Meeting our Region's Needs Today and Tomorrow"
          />
          <Col sm={3} style={{ display: "flex" }}>
            <Row style={{ width: "100%" }}>
              <PortalButton
                to="https://board.ntmwd.com"
                label="Board Resources"
                target="blank"
                title="Board Resources"
                altTitle="Board"
              />
              <PortalButton
                to="https://www.ntmwd.com/login/"
                label="Member Portal"
                target="blank"
                title="Member Portal"
                altTitle="Members"
                color={colors.hex("tertiary")}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </Styled.TopBar>
  );
};

export default TopBar;
