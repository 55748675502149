import React from "react";
import { Col } from "react-bootstrap";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { colors } from "../../../../../../vars/palette";

const StyledDropItem = styled(Col)`
  &,
  .wmk-link {
    color: ${colors.hex("tertiary")};
    font-size: 0.85rem;
    line-height: 2;
    font-weight: 600;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    :hover,
    :focus {
      color: ${colors.hover("tertiary")};
      text-decoration: none;
    }
  }
`;

const MainDropdownItem = ({ children, to, target }) => {
  return to ? (
    <StyledDropItem>
      <WMKLink to={to} target={target}>
        {children}
      </WMKLink>
    </StyledDropItem>
  ) : (
    <StyledDropItem>{children}</StyledDropItem>
  );
};

export default MainDropdownItem;
