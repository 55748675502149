import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import MainDropdown from "./MainDropdown";
import MainSearch from "./MainSearch";
import MainHome from "./MainHome";
import Theme from "../../../../../../vars/ThemeOptions";
import { defaultData } from "./defaultData";
import MainBurgerButton from "./Burger/MainBurgerButton";
import MainBurgerDrawer from "./Burger/MainBurgerDrawer";
import { SearchSliderOpen, SearchSliderDrawer, WMKLink } from "wmk-lib";
import { graphql, useStaticQuery } from "gatsby";
import { colors } from "../../../../../../vars/palette";

const Styled = {};
Styled.MainBar = styled(Container)`
  background-color: ${colors.rgba("white", 0.85)};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 2px 2px 6px 0px ${colors.rgba("text", 0.4)};
  padding: 0;
`;

export const MAIN_MENU_BREAK = 1200;

export const StyledMainIcon = styled.div`
  color: ${colors.hex("primary")};
  padding: 0.5rem 0.75rem;
  .wmk-link {
    color: ${colors.hex("primary")};
    :hover {
      color: ${colors.hex("secondary")};
    }
  }
`;

const MainBar = ({ viewWidth }) => {
  const [mainBar, setMainBar] = useState(0);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const barRef = useRef();
  useEffect(() => {
    const resize = () => {
      setMainBar(barRef.current.getBoundingClientRect());
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);
  const handleClose = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };
  const searchQuery = useStaticQuery(query);
  return (
    <>
      <Styled.MainBar ref={barRef}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={3} /> {/* Spacer */}
          {viewWidth > MAIN_MENU_BREAK ? (
            <>
              <Col>
                <MainDropdown dropdowns={defaultData} mainBar={mainBar} />
              </Col>
              <Col xs="auto">
                <SearchSliderOpen
                  isSearchOpen={isSearchOpen}
                  setIsSearchOpen={setIsSearchOpen}
                  style={{
                    appearance: "none",
                    border: "none",
                    background: "none",
                    paddgin: 0,
                  }}
                >
                  <MainSearch />
                </SearchSliderOpen>
              </Col>
              <Col xs="auto">
                <MainHome home={"https://www.ntmwd.com"} target="self" />
              </Col>
            </>
          ) : (
            <Col
              style={{
                display: "flex",
                WebkitFlex: "initial",
                justifyContent: "flex-end",
                paddingRight: "5vw",
                alignItems: "center",
              }}
            >
              <MainBurgerButton toggle={handleClose} />
            </Col>
          )}
          <Col xs={1} /> {/* Spacer */}
        </Row>
      </Styled.MainBar>
      {isBurgerOpen ? (
        <MainBurgerDrawer dropdowns={defaultData} toggle={handleClose} />
      ) : null}
      {isSearchOpen ? (
        <SearchSliderDrawer
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          query={searchQuery}
          Result={({ result }) => {
            const { title, path, nodeType, slug } = result;
            const to =
              nodeType === "news" ? "/" + slug : Theme.paths.baseUrl + path;
            const target = nodeType === "news" ? undefined : "self";
            return (
              <WMKLink to={to} target={target}>
                {title}
              </WMKLink>
            );
          }}
          style={{
            background: colors.hover("coaccent", 0.2, "flip"),
          }}
        />
      ) : null}
    </>
  );
};

export default MainBar;

const query = graphql`
  query MyQuery {
    pages: allWpPage {
      edges {
        node {
          title
          path: uri
        }
      }
    }
    news: allWpPost {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
