import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import MainDropdownSub from "./MainDropdownSub";
import { colors } from "../../../../../../vars/palette";

const Styled = {};
Styled.Dropdown = styled(Col)`
  font-family: "DIN Alternate", sans-serif;
  position: relative;
  margin-right: 2vw;
  button {
    background: none;
    border: none;
    appearance: none;
    text-transform: uppercase;
    font-size: 12px;
    span {
      color: ${colors.hex("tertiary")};
    }
    @media only screen {
      :focus {
        outline: none;
      }
    }
  }
`;
Styled.OffClick = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -2;
  background: none;
  appearance: none;
  opacity: 0;
  border: none;
  margin: 0;
`;

const MainDropdown = ({ dropdowns, mainBar }) => {
  const [activeTitle, setActiveTitle] = useState();
  const [closeTimer, setCloseTimer] = useState();
  const handleDrop = (title) => {
    const active = activeTitle;
    if (active === title) {
      setActiveTitle(null);
    } else {
      setActiveTitle(title);
    }
  };
  const delayMenuClose = () => {
    setActiveTitle(null);
    clearTimeout(closeTimer);
    setCloseTimer(null);
  };
  const incrementTimeout = () => {
    setCloseTimer(
      setTimeout(() => {
        delayMenuClose();
      }, 400)
    );
  };
  const offClickTimeout = (mode) => {
    if (mode === "enter") {
      incrementTimeout();
    } else {
      clearInterval(closeTimer);
      setCloseTimer(null);
    }
  };

  return dropdowns && dropdowns.length ? (
    <Row
      noGutters
      style={{
        height: mainBar ? mainBar.height : 0,
        justifyContent: "flex-end",
      }}
    >
      {dropdowns.map((d) => {
        const { title, subMenu, video } = d;
        const isActive = activeTitle === title;
        return (
          <Styled.Dropdown key={title} xs="auto">
            <DropButton
              click={() => handleDrop(title)}
              isActive={isActive}
              enter={() => setActiveTitle(title)}
            >
              {title}
            </DropButton>
            {isActive ? (
              <>
                <MainDropdownSub
                  subMenu={subMenu}
                  mainBar={mainBar}
                  video={video}
                />
                <Styled.OffClick
                  onClick={() => setActiveTitle(null)}
                  onMouseEnter={() => offClickTimeout("enter")}
                  onMouseLeave={() => offClickTimeout("exit")}
                >
                  close
                </Styled.OffClick>
              </>
            ) : null}
          </Styled.Dropdown>
        );
      })}
    </Row>
  ) : null;
};

const DropButton = ({ click, children, isActive, enter }) => (
  <button
    onClick={click}
    onMouseEnter={enter}
    style={{
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      background: isActive ? "#f0f0f0" : "none", // #e0e0e0
      color: isActive ? colors.hover("primary", 0.4) : colors.hex("primary"),
    }}
    className="h-100"
  >
    {children}
    <span
      style={{
        color: isActive ? colors.hex("accent") : colors.hex("text"),
        marginLeft: "3px",
        fontSize: "12px",
      }}
    >
      <FaAngleDown
        style={{
          transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
          transition: "all .3s ease",
        }}
      />
    </span>
  </button>
);

export default MainDropdown;
