const menuMedia = {
  title: "Media",
  subMenu: [
    {
      title: "News Room",
      menu: [
        { title: "News & Highlights", to: "/" },
        // {
        //   title: "News Releases",
        //   to: "https://www.ntmwd.com/news-releases/",
        //   target: 'self'
        // },
        // {
        //   title: "Fact Sheets",
        //   to: "https://www.ntmwd.com/fact-sheets/",
        //   target: "self",
        // },
        {
          title: "Open Records",
          to: "https://www.ntmwd.com/open-records/",
          target: "self"
        },
        // {
        //   title: "Media Contacts",
        //   to: "https://www.ntmwd.com/media-contacts/",
        //   target: "self",
        // },
        {
          title: "Newsletters",
          to: "https://www.ntmwd.com/newsletters/",
          target: "self"
        }
      ]
    },
    {
      title: "Multi-Media",
      menu: [
        {
          title: "Photos & Videos",
          to: "/photos-videos"
          //target: "self",
        },
        // {
        //   title: "Videos",
        //   to: "https://www.ntmwd.com/videos/",
        //   target: "self",
        // },
        // {
        //   title: "Infographics",
        //   to: "https://www.ntmwd.com/infographics/",
        //   target: "self",
        // },
        // {
        //   title: "Presentations",
        //   to: "https://www.ntmwd.com/presentations/",
        //   target: "self",
        // },
        {
          title: "Fact Sheets & Infographics",
          to: "https://www.ntmwd.com/fact-sheets/",
          target: "self"
        }
      ]
    },
    {
      title: "Social",
      menu: [
        // { title: "Blog", to: "/tags/blog/" },
        {
          title: "Social Media",
          to: "https://www.ntmwd.com/social-media/",
          target: "self"
        }
        // {
        //   title: "Events",
        //   to: "https://www.ntmwd.com/events/",
        //   target: "self",
        // },
      ]
    }
  ],
  video: {
    url: "https://youtu.be/jEh6sV-9OBs"
  }
};

const menuEducation = {
  title: "Education",
  subMenu: [
    {
      title: "Water Conservation & Reuse",
      menu: [
        {
          title: "Help Us Conserve Water",
          to: "https://www.ntmwd.com/help-us-conserve/",
          target: "self"
        },
        {
          title: "Water Reuse Project",
          to: "https://www.ntmwd.com/east-fork-water-reuse-project/",
          target: "self"
        },
        {
          title: "Value of Water",
          to: "https://www.ntmwd.com/value-of-water/",
          target: "self"
        },
        {
          title: "#MoreThanWater",
          to: "https://www.ntmwd.com/more-than-water/",
          target: "self"
        }
      ]
    },
    {
      title: "Programs",
      menu: [
        {
          title: "Program Overview",
          to: "https://www.ntmwd.com/education-programs/",
          target: "self"
        },
        {
          title: "Water My Yard",
          to: "https://watermyyard.org/#/Location",
          target: "blank"
        },
        {
          title: "Water 4 Otter",
          to: "http://water4otter.org/",
          target: "blank"
        },
        {
          title: "#PledgeToPlantSmart",
          to: "https://www.ntmwd.com/help-us-conserve/pledge-to-plant-smart/",
          target: "self"
        },
        {
          title: "Defend Your Drains",
          to: "https://www.ntmwd.com/defend-your-drains/",
          target: "self"
        }
      ]
    },
    {
      title: "Resources",
      menu: [
        {
          title: "Classes",
          to: "https://www.ntmwd.com/classes/",
          target: "self"
        },
        {
          title: "Lake Levels",
          to: "https://www.ntmwd.com/lake-levels/",
          target: "self"
        },
        {
          title: "Conservation Tips",
          to: "https://www.ntmwd.com/help-us-conserve/easy-ways-to-save/",
          target: "self"
        },
        {
          title: "Partner Links",
          to: "https://www.ntmwd.com/partner-links/",
          target: "self"
        },
        {
          title: "Invasive & Protected Species",
          to: "https://www.ntmwd.com/invasive-protected-species/",
          target: "self"
        }
      ]
    }
  ],
  video: {
    url: "https://youtu.be/eCEZz1WAq48"
  }
};

const menuEnvironment = {
  title: "Environment",
  subMenu: [
    {
      title: "Protecting Water",
      menu: [
        {
          title: "Raw Water Supplies",
          to: "https://www.ntmwd.com/raw-water-supplies/",
          target: "self"
        },
        {
          title: "Water Treatment",
          to: "https://www.ntmwd.com/water-treatment/",
          target: "self"
        },
        {
          title: "Water Quality",
          to: "https://www.ntmwd.com/water-quality/",
          target: "self"
        },
        {
          title: "Water Hardness",
          to: "https://www.ntmwd.com/water-hardness/",
          target: "self"
        },
        {
          title: "Taste and Odor",
          to: "https://www.ntmwd.com/taste-odor/",
          target: "self"
        }
      ]
    },
    {
      title: "Programs & Services",
      menu: [
        {
          title: "About the Laboratory",
          to: "https://www.ntmwd.com/about-the-laboratory/",
          target: "self"
        },
        {
          title: "Environmental Services",
          to: "https://www.ntmwd.com/environmental-services/",
          target: "self"
        },
        {
          title: "Pretreamtment",
          to: "https://www.ntmwd.com/pretreatment/",
          target: "self"
        },
        {
          title: "Watershed Protection",
          to: "https://www.ntmwd.com/watershed/",
          target: "self"
        },
        {
          title: "Stormwater Management",
          to: "https://www.ntmwd.com/stormwater-management/",
          target: "self"
        }
      ]
    },
    {
      title: "Resources",
      menu: [
        {
          title: "Environmental Impacts",
          to: "https://www.ntmwd.com/environmental-impacts/",
          target: "self"
        },
        {
          title: "Invasive & Protected Species",
          to: "https://www.ntmwd.com/invasive-protected-species/",
          target: "self"
        },
        {
          title: "Lake Levels",
          to: "https://www.ntmwd.com/lake-levels/",
          target: "self"
        },
        {
          title: "Water Quality Reports",
          to: "https://www.ntmwd.com/water-quality-reports/",
          target: "self"
        }
      ]
    }
  ],
  video: {
    url: "https://youtu.be/QoqgzJAf6LQ"
  }
};

const menuServices = {
  title: "Services",
  subMenu: [
    {
      title: "Services",
      menu: [
        {
          title: "Water",
          to: "https://www.ntmwd.com/our-water-system/",
          target: "self"
        },
        {
          title: "Wastewater",
          to: "https://www.ntmwd.com/our-wastewater-system/",
          target: "self"
        },
        {
          title: "Solid Waste",
          to: "https://www.ntmwd.com/our-solid-waste-system/",
          target: "self"
        }
      ]
    },
    {
      title: "Key Projects",
      menu: [
        {
          title: `Bois d'Arc Lake`,
          to: "https://boisdarclake.org/",
          target: "blank"
        },
        {
          title: "Sister Grove Regional Water Resource Recovery Facility",
          to: "https://www.ntmwd.com/projects/new-regional-water-resource-recovery-facility/",
          target: "self"
        },
        {
          title: "McKinney-Prosper Transfer Sewer Pipeline",
          to: "https://www.ntmwd.com/projects/mckinney-prosper-transfer-sewer-pipeline/",
          target: "self"
        },
        {
          title: "Wilson Creek Plant Expansion",
          to: "https://www.ntmwd.com/projects/wilson-creek-plant-expansion/",
          target: "self"
        },
        {
          title: "Renewable Natural Gas Project",
          to: "https://www.ntmwd.com/projects/renewable-natural-gas-production/",
          target: "self"
        }
      ]
    },
    {
      title: "Resources",
      menu: [
        {
          title: "Solid Waste Facilities",
          to: "https://www.ntmwd.com/facilities/",
          target: "self"
        },
        {
          title: "Projects",
          to: "https://www.ntmwd.com/projects/",
          target: "self"
        },
        {
          title: "Business Oppourtunities",
          to: "https://www.ntmwd.com/business-opportunities/",
          target: "self"
        },
        {
          title: "Purchasing Department",
          target: "self",
          to: "https://www.ntmwd.com/purchasing-department/"
        },
        {
          title: "Water Quality",
          to: "https://www.ntmwd.com/water-quality/",
          target: "self"
        }
      ]
    }
  ],
  video: {
    url: "https://youtu.be/jEh6sV-9OBs"
  }
};

const menuAboutUs = {
  title: "About Us",
  subMenu: [
    {
      title: "Overview",
      menu: [
        {
          title: "Who We Are",
          to: "https://www.ntmwd.com/who-we-are/",
          target: "self"
        },
        {
          title: "History",
          to: "https://www.ntmwd.com/history/",
          target: "self"
        },
        {
          title: "Services",
          to: "https://www.ntmwd.com/services/",
          target: "self"
        },
        {
          title: "Finances",
          to: "https://www.ntmwd.com/finances/",
          target: "self"
        },
        {
          title: "Board of Directors",
          to: "https://www.ntmwd.com/board-of-directors/",
          target: "self"
        },
        {
          title: "Leadership",
          to: "https://www.ntmwd.com/leadership/",
          target: "self"
        }
      ]
    },
    {
      title: "Work With Us",
      menu: [
        {
          title: "Careers",
          to: "https://www.ntmwd.com/careers/",
          target: "self"
        },
        {
          title: "Business Opportunities",
          to: "https://www.ntmwd.com/business-opportunities/",
          target: "self"
        },
        {
          title: "Purchasing Department",
          to: "https://www.ntmwd.com/purchasing-department/",
          target: "self"
        },
        {
          title: "Projects",
          to: "https://www.ntmwd.com/projects/",
          target: "self"
        }
      ]
    },
    {
      title: "Resources",
      menu: [
        {
          title: "Financial Transparency",
          to: "https://www.ntmwd.com/finances/traditional-finances/",
          target: "self"
        },
        {
          title: "Annual Report",
          to: "https://www.ntmwd.com/ntmwd-2019-annual-report/",
          target: "self"
        },
        {
          title: "Board Documents",
          to: "https://www.ntmwd.com/board-of-directors/board-meetings/",
          target: "self"
        },
        {
          title: "Public Notices",
          to: "https://www.ntmwd.com/public-notices/",
          target: "self"
        },
        {
          title: "#MoreThanWater",
          to: "https://www.ntmwd.com/more-than-water/",
          target: "self"
        },
        {
          title: "Value of Water",
          to: "https://www.ntmwd.com/value-of-water/",
          target: "self"
        },
        {
          title: "Water Rates",
          to: "https://www.ntmwd.com/water-rates/",
          target: "self"
        }
      ]
    }
  ],
  video: {
    url: "https://youtu.be/r5hRWd_II1Q"
  }
};

export const defaultData = [
  menuAboutUs,
  menuServices,
  menuEnvironment,
  menuEducation,
  menuMedia
];
