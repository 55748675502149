import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../vars/palette";

const Styled = {};
Styled.Footer = styled.div`
  height: 1.5vh;
  width: 100%;
  background: ${colors.hex("secondary")};
  margin-top: 3vh;
`;

export const PortalFooter = () => {
  return <Styled.Footer />;
};
