import React from "react";
import { SocialMediaIcons } from "wmk-lib";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
//import { FaPinterestP, FaYelp } from "react-icons/fa";
import styled from "styled-components";
import { Button } from "../../../ntmwd";

const Styled = {};
Styled.Icons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .wmk-link {
    font-size: 4vh;
  }
`;

const SocialArea = () => {
  return (
    <>
      <Styled.Icons>
        <SocialMediaIcons
          query={[
            {
              title: "facebook",
              url: "https://www.facebook.com/NTMWD",
              target: "blank",
            },
            {
              title: "twitter",
              url: "https://twitter.com/NTMWD",
              target: "blank",
            },
            {
              title: "linkedin",
              url:
                "https://www.linkedin.com/company/north-texas-municipal-water-district",
              target: "blank",
            },
            {
              title: "youtube",
              url:
                "https://www.youtube.com/channel/UCCPiofTEARsi_rARdGQDe8A?spfreload=5",
              target: "blank",
            },
            {
              title: "instagram",
              url: "https://www.instagram.com/NTMWD/",
              target: "blank",
            },
          ]}
          platforms={platforms}
        />
      </Styled.Icons>
      <div style={{padding: '2vh 0', display: 'flex', justifyContent: 'center'}}>
        <Button to={"https://www.ntmwd.com/newsletters/"} target="self">
          Sign up for our e-Newsletter
        </Button>
      </div>
    </>
  );
};

export default SocialArea;

const platforms = [
  { name: "facebook", Icon: AiFillFacebook },
  { name: "twitter", Icon: AiFillTwitterSquare },
  { name: "linkedin", Icon: AiFillLinkedin },
  { name: "youtube", Icon: AiFillYoutube },
  { name: "instagram", Icon: AiFillInstagram },
  //   { name: "pinterest", Icon: FaPinterestP },
  //   { name: "yelp", Icon: FaYelp },
];
