import React from "react";

export const NTMWDLogo = React.forwardRef((props, ref) => {
  return (
    <svg
      viewBox="2854 77 126.891 93.917"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-label="North Texas Municiapl Water District Logo"
    >
      <g fill="#005cb9">
        <path
          d="m100.8 12.6h1.325l2.061 5.667v-5.667h1.325v9.421h-1.251l-2.06-5.667v5.667h-1.325v-9.421z"
          transform="translate(2827.391 73.674)"
        />
        <path
          d="m110.3 14.855a2.351 2.351 0 0 1 .221-1.03 2.644 2.644 0 0 1 .515-.736 1.527 1.527 0 0 1 .736-.442 2.3 2.3 0 0 1 1.619 0 1.527 1.527 0 0 1 .736.442 2.643 2.643 0 0 1 .515.736 2.351 2.351 0 0 1 .221 1.03v5a2.351 2.351 0 0 1 -.221 1.03 2.643 2.643 0 0 1 -.515.736 1.527 1.527 0 0 1 -.736.442 2.3 2.3 0 0 1 -1.619 0 1.527 1.527 0 0 1 -.736-.442 2.644 2.644 0 0 1 -.515-.736 2.138 2.138 0 0 1 -.221-1.03zm1.325 4.931a.853.853 0 0 0 .294.736 1.1 1.1 0 0 0 1.325 0 .775.775 0 0 0 .294-.736v-5a.853.853 0 0 0 -.294-.736.9.9 0 0 0 -.662-.221 1.028 1.028 0 0 0 -.662.221.981.981 0 0 0 -.294.736z"
          transform="translate(2824.883 73.7)"
        />
        <path
          d="m119.5 12.6h2.208c1.619 0 2.355.957 2.355 2.8a2.8 2.8 0 0 1 -.294 1.4 2.2 2.2 0 0 1 -.883.957l1.472 4.269h-1.4l-1.251-4.048h-.81v4.048h-1.325v-9.426zm1.325 4.269h.736a1.435 1.435 0 0 0 .589-.074.575.575 0 0 0 .294-.294 1.046 1.046 0 0 0 .147-.442c0-.147.074-.368.074-.662a1.827 1.827 0 0 0 -.074-.662.789.789 0 0 0 -.147-.442.735.735 0 0 0 -.368-.294 1.435 1.435 0 0 0 -.589-.074h-.736v2.944z"
          transform="translate(2822.454 73.674)"
        />
        <path
          d="m128.946 13.951h-1.546v-1.251h4.49v1.251h-1.546v8.17h-1.325v-8.17z"
          transform="translate(2820.369 73.647)"
        />
        <path
          d="m135.8 12.6h1.325v4.048h1.693v-4.048h1.325v9.421h-1.325v-4.2h-1.693v4.2h-1.325z"
          transform="translate(2818.152 73.674)"
        />
        <path
          d="m101.546 36.951h-1.546v-1.251h4.49v1.251h-1.546v8.17h-1.325v-8.17z"
          transform="translate(2827.602 67.576)"
        />
        <path
          d="m108.4 35.6h4.048v1.251h-2.648v2.8h2.355v1.249h-2.355v2.8h2.65v1.325h-4.05z"
          transform="translate(2825.385 67.602)"
        />
        <path
          d="m117.514 40.19-1.766-4.49h1.4l1.03 2.8 1.03-2.8h1.4l-1.766 4.49 1.914 4.931h-1.4l-1.178-3.165-1.178 3.165h-1.4z"
          transform="translate(2823.484 67.576)"
        />
        <path
          d="m126.287 35.6h1.1l2.134 9.421h-1.321l-.368-2.061h-1.84l-.368 2.061h-1.324zm.589 2.723-.662 3.386h1.325z"
          transform="translate(2821.187 67.602)"
        />
        <path
          d="m138.037 38.323h-1.325v-.294a1.484 1.484 0 0 0 -.221-.81.812.812 0 0 0 -.736-.368c-.221 0-.368 0-.442.074a.575.575 0 0 0 -.294.294c-.074.147-.147.221-.147.368s-.074.294-.074.442v.442a3.188 3.188 0 0 1 .074.368c0 .147.147.147.221.221a1.2 1.2 0 0 0 .442.221l1.03.442a6.843 6.843 0 0 1 .736.368c.221.147.294.368.442.515.074.221.147.442.221.736 0 .294.074.589.074.957a5.684 5.684 0 0 1 -.147 1.1 2.455 2.455 0 0 1 -.368.883 2 2 0 0 1 -.736.589 2.458 2.458 0 0 1 -1.1.221 3.163 3.163 0 0 1 -.883-.147 6.475 6.475 0 0 1 -.736-.442 2.642 2.642 0 0 1 -.515-.736 1.867 1.867 0 0 1 -.147-.883v-.515h1.325v.442a1.187 1.187 0 0 0 .221.662.775.775 0 0 0 .736.294c.221 0 .442 0 .515-.074a.574.574 0 0 0 .294-.294c.074-.147.074-.294.147-.442v-1.174a.562.562 0 0 0 -.147-.368l-.221-.221a1.608 1.608 0 0 0 -.368-.221l-.957-.368a2.262 2.262 0 0 1 -1.178-.957 2.919 2.919 0 0 1 -.294-1.472 4.971 4.971 0 0 1 .147-1.03 1.754 1.754 0 0 1 .442-.81 1.683 1.683 0 0 1 .736-.515 2.351 2.351 0 0 1 1.03-.221 2.037 2.037 0 0 1 .957.221 2.644 2.644 0 0 1 .736.515 1.738 1.738 0 0 1 .442.662 2.645 2.645 0 0 1 .147.81v.515z"
          transform="translate(2818.785 67.602)"
        />
        <path
          d="m100.9 58.6h1.325l1.766 5 1.693-5h1.325v9.421h-1.325v-5.741l-1.325 4.048h-.659l-1.325-4.048v5.741h-1.325v-9.421z"
          transform="translate(2827.364 61.531)"
        />
        <path
          d="m116.769 65.987a3.7 3.7 0 0 1 -.147.883 2.568 2.568 0 0 1 -.442.662 2.015 2.015 0 0 1 -1.546.589 3.163 3.163 0 0 1 -.883-.147 2.568 2.568 0 0 1 -.662-.442 1.738 1.738 0 0 1 -.442-.662 1.867 1.867 0 0 1 -.147-.883v-7.287h1.325v7.213a.977.977 0 0 0 .221.736.9.9 0 0 0 1.178 0 1.1 1.1 0 0 0 .221-.736v-7.213h1.325v7.287z"
          transform="translate(2824.302 61.504)"
        />
        <path
          d="m121.5 58.6h1.325l2.061 5.667v-5.667h1.325v9.421h-1.251l-2.06-5.667v5.667h-1.325v-9.421z"
          transform="translate(2821.926 61.531)"
        />
        <path
          d="m131 58.6h1.325v9.421h-1.325z"
          transform="translate(2819.419 61.531)"
        />
        <path
          d="m140.443 65.907a2.647 2.647 0 0 1 -.147.81 1.5 1.5 0 0 1 -.442.662 1.738 1.738 0 0 1 -.662.442 2.646 2.646 0 0 1 -.81.147 3.3 3.3 0 0 1 -.81-.074 2.3 2.3 0 0 1 -.736-.368 1.6 1.6 0 0 1 -.515-.662 2.458 2.458 0 0 1 -.221-1.1v-5.23a3.7 3.7 0 0 1 .147-.883 1.072 1.072 0 0 1 .442-.662 1.737 1.737 0 0 1 .662-.442 2.723 2.723 0 0 1 1.766 0 2.568 2.568 0 0 1 .662.442 1.527 1.527 0 0 1 .442.736 1.867 1.867 0 0 1 .147.883v.515h-1.325v-.442a.9.9 0 0 0 -.221-.662.669.669 0 0 0 -.589-.294c-.368 0-.589.074-.662.294a1.336 1.336 0 0 0 -.147.81v4.858a1.47 1.47 0 0 0 .147.662.717.717 0 0 0 .662.294.556.556 0 0 0 .294-.074c.147-.074.221-.074.294-.147a1.017 1.017 0 0 0 .221-.294.934.934 0 0 0 .074-.442v-.442h1.325v.662z"
          transform="translate(2818.072 61.584)"
        />
        <path
          d="m145 58.6h1.325v9.421h-1.325z"
          transform="translate(2815.723 61.531)"
        />
        <path
          d="m150.1 58.6h2.061a3.73 3.73 0 0 1 .957.147 1.919 1.919 0 0 1 1.178 1.4 6.6 6.6 0 0 1 .147 1.325 4.213 4.213 0 0 1 -.074 1.03 2.638 2.638 0 0 1 -.294.81 3.491 3.491 0 0 1 -.736.736 2.847 2.847 0 0 1 -1.251.294h-.662v3.68h-1.326zm1.325 4.49h.662a1.47 1.47 0 0 0 .662-.147 1.606 1.606 0 0 0 .368-.294 1.064 1.064 0 0 0 .147-.515v-1.326a1.064 1.064 0 0 0 -.147-.515.735.735 0 0 0 -.294-.368 1.387 1.387 0 0 0 -.589-.147h-.734v3.312z"
          transform="translate(2814.377 61.531)"
        />
        <path
          d="m159.587 58.6h1.1l2.134 9.421h-1.321l-.368-2.061h-1.84l-.368 2.061h-1.324zm.515 2.723-.662 3.386h1.325z"
          transform="translate(2812.397 61.531)"
        />
        <path
          d="m167 58.6h1.325v8.1h2.65v1.325h-3.975z"
          transform="translate(2809.916 61.531)"
        />
        <path
          d="m106.362 91.121h-1.251l-1.03-6.109-1.03 6.109h-1.251l-1.4-9.421h1.4l.662 5.962 1.03-5.962h1.03l1.1 6.109.662-6.109h1.4z"
          transform="translate(2827.496 55.433)"
        />
        <path
          d="m113.787 81.6h1.1l2.134 9.421h-1.321l-.368-2.061h-1.84l-.368 2.061h-1.324zm.589 2.723-.662 3.386h1.325z"
          transform="translate(2824.487 55.459)"
        />
        <path
          d="m120.946 82.951h-1.546v-1.251h4.49v1.251h-1.546v8.17h-1.325v-8.17z"
          transform="translate(2822.481 55.433)"
        />
        <path
          d="m127.8 81.6h4.048v1.251h-2.648v2.8h2.355v1.249h-2.355v2.8h2.65v1.325h-4.05z"
          transform="translate(2820.263 55.459)"
        />
        <path
          d="m135.9 81.6h2.208c1.619 0 2.355.957 2.355 2.8a2.8 2.8 0 0 1 -.294 1.4 2.2 2.2 0 0 1 -.883.957l1.472 4.269h-1.4l-1.251-4.048h-.81v4.048h-1.325v-9.426zm1.325 4.269h.736a1.435 1.435 0 0 0 .589-.074.575.575 0 0 0 .294-.294 1.047 1.047 0 0 0 .147-.442c0-.147.074-.368.074-.662a1.828 1.828 0 0 0 -.074-.662.789.789 0 0 0 -.147-.442.735.735 0 0 0 -.368-.294 1.435 1.435 0 0 0 -.589-.074h-.736v2.944z"
          transform="translate(2818.125 55.459)"
        />
        <path
          d="m100.874 104.6h1.987a2.387 2.387 0 0 1 1.766.662 2.346 2.346 0 0 1 .589 1.766v4.416a2.7 2.7 0 0 1 -.662 1.987 2.449 2.449 0 0 1 -1.84.662h-1.914v-9.493zm1.325 8.17h.589a.917.917 0 0 0 .81-.294 1.57 1.57 0 0 0 .221-.883v-4.493a1.57 1.57 0 0 0 -.221-.883.917.917 0 0 0 -.81-.294h-.588z"
          transform="translate(2827.391 49.388)"
        />
        <path
          d="m110.1 104.6h1.325v9.421h-1.325z"
          transform="translate(2824.936 49.388)"
        />
        <path
          d="m119.437 107.323h-1.325v-.294a1.483 1.483 0 0 0 -.221-.81.812.812 0 0 0 -.736-.368c-.221 0-.368 0-.442.074a.575.575 0 0 0 -.294.294c-.074.147-.147.221-.147.368s-.074.294-.074.442v.442a3.19 3.19 0 0 1 .074.368c0 .147.147.147.221.221a1.2 1.2 0 0 0 .442.221l1.03.442a6.844 6.844 0 0 1 .736.368c.221.147.294.368.442.515.074.221.147.442.221.736 0 .294.074.589.074.957a5.682 5.682 0 0 1 -.147 1.1 2.456 2.456 0 0 1 -.368.883 2 2 0 0 1 -.736.589 2.458 2.458 0 0 1 -1.1.221 3.163 3.163 0 0 1 -.883-.147 6.474 6.474 0 0 1 -.736-.442 2.643 2.643 0 0 1 -.515-.736 1.867 1.867 0 0 1 -.147-.883v-.515h1.325v.442a1.187 1.187 0 0 0 .221.662.775.775 0 0 0 .736.294c.221 0 .442 0 .515-.074a.574.574 0 0 0 .294-.294c.074-.147.074-.294.147-.442v-1.178a.562.562 0 0 0 -.147-.368l-.221-.221a1.608 1.608 0 0 0 -.368-.221l-.957-.368a2.262 2.262 0 0 1 -1.178-.957 2.919 2.919 0 0 1 -.294-1.472 4.972 4.972 0 0 1 .147-1.03 1.755 1.755 0 0 1 .442-.81 1.682 1.682 0 0 1 .736-.515 2.351 2.351 0 0 1 1.03-.221 2.038 2.038 0 0 1 .957.221 2.644 2.644 0 0 1 .736.515 1.738 1.738 0 0 1 .442.662 2.645 2.645 0 0 1 .147.81v.515z"
          transform="translate(2823.695 49.388)"
        />
        <path
          d="m124.646 105.951h-1.546v-1.251h4.49v1.251h-1.62v8.17h-1.325z"
          transform="translate(2821.504 49.361)"
        />
        <path
          d="m131.4 104.6h2.208c1.619 0 2.355.957 2.355 2.8a2.8 2.8 0 0 1 -.294 1.4 2.2 2.2 0 0 1 -.883.957l1.472 4.269h-1.4l-1.251-4.048h-.81v4.048h-1.325v-9.426zm1.4 4.269h.736a1.435 1.435 0 0 0 .589-.074.574.574 0 0 0 .294-.294 1.046 1.046 0 0 0 .147-.442c0-.147.074-.368.074-.662a1.827 1.827 0 0 0 -.074-.662.789.789 0 0 0 -.147-.442.735.735 0 0 0 -.368-.294 1.435 1.435 0 0 0 -.589-.074h-.736v2.944z"
          transform="translate(2819.313 49.388)"
        />
        <path
          d="m140.6 104.6h1.325v9.421h-1.325z"
          transform="translate(2816.885 49.388)"
        />
        <path
          d="m150.043 111.907a2.645 2.645 0 0 1 -.147.81 1.5 1.5 0 0 1 -.442.662 1.738 1.738 0 0 1 -.662.442 2.646 2.646 0 0 1 -.81.147 3.3 3.3 0 0 1 -.81-.074 2.3 2.3 0 0 1 -.736-.368 1.6 1.6 0 0 1 -.515-.662 2.458 2.458 0 0 1 -.221-1.1v-5.226a3.694 3.694 0 0 1 .147-.883 1.072 1.072 0 0 1 .442-.662 1.738 1.738 0 0 1 .662-.442 2.723 2.723 0 0 1 1.766 0 2.569 2.569 0 0 1 .662.442 1.527 1.527 0 0 1 .442.736 1.867 1.867 0 0 1 .147.883v.515h-1.325v-.442a.9.9 0 0 0 -.221-.662.669.669 0 0 0 -.589-.294c-.368 0-.589.074-.662.294a1.336 1.336 0 0 0 -.147.81v4.858a1.469 1.469 0 0 0 .147.662.717.717 0 0 0 .662.294.556.556 0 0 0 .294-.074c.147-.074.221-.074.294-.147a1.015 1.015 0 0 0 .221-.294.933.933 0 0 0 .074-.442v-.442h1.325v.662z"
          transform="translate(2815.538 49.441)"
        />
        <path
          d="m155.146 105.951h-1.546v-1.251h4.49v1.251h-1.546v8.17h-1.325v-8.17z"
          transform="translate(2813.453 49.361)"
        />
      </g>
      <g transform="translate(2854 77)">
        <path
          d="m69.481 86.63c.294 4.711-4.2 7.287-6.551 7.287h-56.379a6.743 6.743 0 0 1 -6.551-6.917v-80.081c0-2.429 1.4-6.919 6.551-6.919h56.749c2.429 0 6.035 1.546 6.183 6.919z"
          fill="#005cb9"
        />
        <path
          d="m66.167 86.039a6.186 6.186 0 0 1 -5.52 6.551h-47.327a5.912 5.912 0 0 1 -5.52-6.256v-72.278c0-2.134 1.178-6.256 5.52-6.256h47.621c1.987 0 5.079 1.4 5.226 6.256z"
          fill="#fff"
          transform="translate(-2.059 -2.059)"
        />
        <path
          d="m65.349 80.874a5.777 5.777 0 0 1 -5.226 5.962h-44.9a5.46 5.46 0 0 1 -5.223-5.668v-65.8c0-1.988 1.1-5.668 5.226-5.668h45.192c1.914 0 4.784 1.251 4.931 5.667z"
          fill="#00af41"
          transform="translate(-2.64 -2.561)"
        />
        <path
          d="m8 107.1s.294 4.2 3.533 4.784c0 0 3.239.515 5-1.251 0 0 2.355 2.87 6.624 0a5.191 5.191 0 0 0 6.992 0 4.946 4.946 0 0 0 6.845 0s2.87 3.165 6.919 0a4.771 4.771 0 0 0 7.066 0s2.723 3.165 7.213-.074c0 0 2.5 2.134 5.226 1.178 0 0 1.03-.957 1.766-4.343l.736 1.1-.736 5.594-55.2 1.178-1.546-3.827z"
          fill="#fff"
          transform="translate(-2.112 -28.272)"
        />
        <path
          d="m7.383 113.6s0 4.2 3.533 4.784c0 0 3.239.515 5-1.251 0 0 2.355 2.871 6.624 0a5.191 5.191 0 0 0 6.992 0 4.946 4.946 0 0 0 6.845 0s2.871 3.165 6.919 0c0 0 3.68 3.754 7.066 0 0 0 2.723 3.165 7.213-.074 0 0 2.5 2.134 5.226 1.178a4.292 4.292 0 0 0 3.533-3.68l2.723 2.429-3.9 4.269-55.717.515-2.94-4.122z"
          fill="#005cb9"
          transform="translate(-1.716 -29.988)"
        />
        <path
          d="m13.335 111.826s-3.459.442-3.239-4.269l6.919-.957z"
          fill="#00af41"
          transform="translate(-2.663 -28.14)"
        />
        <path
          d="m75.8 106 3.68 5.741s3.459.515 3.239-4.637"
          fill="#00af41"
          transform="translate(-20.009 -27.981)"
        />
        <path
          d="m64.783 77.384c.221 3.607-2.134 5.667-4.711 5.594h-44.161c-3.091.221-4.711-1.546-4.711-5.3v-61.678c0-1.84 1.03-5.3 5.079-5.3h43.721c1.84 0 4.637 1.178 4.784 5.3z"
          fill="#fff"
          transform="translate(-2.957 -2.825)"
        />
        <g fill="#00af41">
          <path
            d="m65.8 92.089a13.813 13.813 0 0 0 -3.386 1.4 7.194 7.194 0 0 1 -4.858 1.693s-4.122-.147-6.256-1.766c0 0-2.134-1.4-3.754-1.178l-.147-.294.883-.147a15.037 15.037 0 0 1 9.348-3.9c-.073-.003 5.595-.518 8.17 4.192z"
            transform="translate(-12.512 -23.197)"
          />
          <path
            d="m65.485 78.536a11.191 11.191 0 0 0 -8.317-4.636s-2.944-.221-9.568 4.269l.074.221a2.988 2.988 0 0 1 2.208-.662 6.745 6.745 0 0 1 2.944 1.693 9.283 9.283 0 0 0 5.074 1.618s1.4.221 4.2-1.619a8.934 8.934 0 0 1 3.385-.884z"
            transform="translate(-12.565 -19.507)"
          />
          <path
            d="m65.406 63.941s-1.914-2.723-3.975-3.386a13.558 13.558 0 0 0 -4.416-.957 9.668 9.668 0 0 0 -4.2 1.178.743.743 0 0 1 -.368.147s-2.208 1.546-2.723 1.914a3.84 3.84 0 0 1 -2.424.663l.074.442a4.864 4.864 0 0 1 3.9.294 6.714 6.714 0 0 0 5.079 2.723 7.175 7.175 0 0 0 5.373-1.693c2.503-2.061 2.429-1.546 3.68-1.325z"
            transform="translate(-12.486 -15.731)"
          />
          <path
            d="m64.755 41.953a8.631 8.631 0 0 0 -7.875-.589s-5.373 2.429-6.183 5.3c0 0-1.693 3.386-2.576 3.312l-.221.589s2.282-1.03 3.607-.589a7.9 7.9 0 0 0 4.637-.074 11.142 11.142 0 0 0 6.551-4.931c-.148.074 1.105-2.723 2.06-3.018z"
            transform="translate(-12.644 -10.746)"
          />
          <path
            d="m47.351 35.3s3.607-1.766 3.827-7.507a11.48 11.48 0 0 0 -4.637-8.393 1.888 1.888 0 0 1 .221 1.766 37.862 37.862 0 0 0 -2.576 5.667s-.883 2.134.294 5.741a17.918 17.918 0 0 0 1.62 3.755z"
            transform="translate(-11.58 -5.121)"
          />
          <path
            d="m41.819 43.8a6.086 6.086 0 0 1 -2.134-3.239s-1.693-5.594-8.906-5.962c0 0-3.754-.074-5.079 1.1 0 0 .957.074 1.03.589.074.442 1.03 4.343 3.827 5.815a11.794 11.794 0 0 0 7.213 1.766s1.84-.442 2.355-.147a1.447 1.447 0 0 1 1.251 1.251z"
            transform="translate(-6.784 -9.133)"
          />
          <path
            d="m40.58 55.856a27.813 27.813 0 0 0 -6.845-4.416s-4.122-1.251-6.771.442c0 0-3.9 2.282-4.563 3.312 0 0 1.325.074 1.693.442a9.809 9.809 0 0 0 6.183 2.355 14.012 14.012 0 0 0 5.815-1.1s2.8-1.693 4.269-.589z"
            transform="translate(-5.913 -13.461)"
          />
          <path
            d="m40.416 69.93-.589-.147s-4.2-3.68-6.992-4.269a11.564 11.564 0 0 0 -6.992.736s-4.122 2.355-4.343 3.312a4.561 4.561 0 0 1 2.65.589s3.165 2.429 6.183 2.282c0 0 3.9-.147 6.33-1.914 0 0 2.355-1.178 3.68.147z"
            transform="translate(-5.675 -17.23)"
          />
          <path
            d="m40.127 83.325s-5.3-5.3-9.863-4.931c0 0-6.4 0-8.464 5a1.857 1.857 0 0 1 2.208 0 9.44 9.44 0 0 0 6.7 2.282s3.533.074 5.52-1.84a4.119 4.119 0 0 1 2.134-1.251 2.1 2.1 0 0 1 1.84 1.178"
            transform="translate(-5.754 -20.689)"
          />
          <path
            d="m39.7 97.712a13.7 13.7 0 0 0 -6.919-5.667 11.307 11.307 0 0 0 -7.949.662s-3.602 1.914-4.632 3.975c0 0 .736-.883 2.282-.074 0 0 3.386 2.134 5.373 2.355a10.856 10.856 0 0 0 5.962-.589 4.784 4.784 0 0 0 1.914-1.1 3.142 3.142 0 0 1 1.178-.736 1.94 1.94 0 0 1 1.325-.147 3.736 3.736 0 0 1 1.325 1.84"
            transform="translate(-5.332 -24.183)"
          />
          <path
            d="m47.793 41.1v9.2l-.368.442v38.936h-1.325v-36.213l.294-.368v-7.286l.294-.589v-4.122z"
            transform="translate(-12.169 -10.849)"
          />
          <path
            d="m46.111 104.647s.368 2.87 4.637 3.239v.883h-9.348v-.957s3.459-.368 3.459-3.312"
            transform="translate(-10.928 -27.585)"
          />
        </g>
        <path
          d="m79.3 112.338s3.165 1.03 4.269-3.238l.515.074-.515 3.9-3.533.883z"
          fill="#fff"
          transform="translate(-20.933 -28.8)"
        />
      </g>
    </svg>
  );
});
