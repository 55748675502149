import React from "react";
import { WMKLink } from "wmk-lib";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";

const Styled = {};
Styled.Button = styled(WMKLink)`
  background-color: ${colors.hex("primary")};
  padding: 0.7rem 1rem;
  border-radius: 2px;
  color: ${colors.hex("white")};
  transition: all 0.45s ease;
  :hover {
    text-decoration: none;
    background-color: ${colors.hex("accent")};
    transition: all 0.3s ease;
    color: ${colors.hex("white")};
  }
`;

export const Button = ({ children, to, target }) => {
  return (
    <Styled.Button to={to} target={target ? target : undefined}>
      {children}
    </Styled.Button>
  );
};
