/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NTMWDFooter, HeaderHybrid } from "../common/ntmwd";
import { MainLayout, Sticky } from "wmk-lib";
import AlertBar from "../common/ntmwd/layout/Header/Alert/AlertBar";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";

const GlobalStyles = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
  .din {
    font-family: "DIN Alternate", sans-serif;
    font-weight: bold;
  }
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #575757;
  main {
    padding: 8vh 0 2.5vh 0;
    maxwidth: 900px;
    margin: 0;
  }
`;

const Layout = ({ children }) => {
  const { wp } = useStaticQuery(query);
  const { alertBar } = wp;
  const { settings } = alertBar;
  const { alertToggle } = settings;
  const alert = get(settings, "alerts[0]");
  const _target = get(alert, "alertSystemLink.target");
  const __target = _target !== "" && _target ? _target : "self";
  return (
    <GlobalStyles>
      <MainLayout
        Header={() => (
          <Sticky
            Alert={
              alertToggle ? (
                <AlertBar
                  cta={{
                    to: get(alert, "alertSystemLink.url"),
                    target: __target,
                    text: get(alert, "alertSystemLink.title", "Read More"),
                  }}
                >
                  {get(alert, "alertSystemText")}
                </AlertBar>
              ) : null
            }
          >
            <HeaderHybrid />
          </Sticky>
        )}
        Footer={NTMWDFooter}
      >
        {children}
      </MainLayout>
    </GlobalStyles>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const query = graphql`
  {
    wp {
      ...NodeAlertBarFields
    }
  }
`;
