import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import MainDropdownItem from "../MainDropdownItem";

const StyledSubHeader = styled.button`
  text-transform: uppercase;
  font-family: "DIN Alternate", sans-serif;
  appearance: none;
  border: none;
  background: none;
  h6 {
    margin: 0 0 1vh 0; 
  }
`;

const StlyedMenuItem = styled(Col)`
  //padding-left: 5vw;
  line-height: 1.75;
  font-family: "DIN Alternate", sans-serif;
`;

const MainBurgerSubMenu = ({ title, menu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Row className="flex-column" key={"sub-" + title}>
      <Col>
        <StyledSubHeader onClick={handleClick}>
          <h6>{title}</h6>
        </StyledSubHeader>
      </Col>
      {isOpen
        ? menu.map((item) => {
            const { title, to, target } = item;
            return (
              <StlyedMenuItem key={"item-" + title}>
                <MainDropdownItem to={to} target={target}>
                  {title}
                </MainDropdownItem>
              </StlyedMenuItem>
            );
          })
        : null}
    </Row>
  );
};

export default MainBurgerSubMenu;
