import React from "react";
import { Col } from "react-bootstrap";

const TagLine = ({ main, extra }) => {
  return (
    <Col
      xs="auto"
      //sm={5}
      md={6}
      style={{
        textAlign: "center",
        padding: ".5vh 3vh",
        alignItems: "center",
        //width: user ? "auto" : "100%",
      }}
      className="tagline d-none d-sm-flex"
    >
      {main}
      <span>{extra}</span>
    </Col>
  );
};

export default TagLine;
