import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ContactInfo from "./ContactInfo";
import UsefulLinks from "./UsefulLinks";
import SocialArea from "./SocialArea";
import { colors } from "../../../../../vars/palette";

const Styled = {};
Styled.Footer = styled.footer`
  background-color: ${colors
    .primary()
    .color.desaturate(0.4)
    .lighten(0.25)
    .hex()};
  color: ${colors.hex("white")};
  padding: 4vh 6vh;
  font-size: 0.85rem;
  .wmk-link {
    color: ${colors.hex("white")};
  }
`;

const footerLinks = [
  {
    to: `https://www.ntmwd.com/contact-us/`,
    text: "Contact Us",
    target: "self",
  },
  { to: "https://www.ntmwd.com/careers/", text: "Careers", target: "self" },
  { to: `https://www.ntmwd.com/login/`, text: "Member Login", target: "self" },
  { to: `https://www.ntmwd.com/sitemap/`, text: "Sitemap", target: "self" },
  { to: `https://www.ntmwd.com/legal/`, text: "Legal", target: "self" },
  {
    to: `mailto:webmaster@ntmwd.com`,
    text: "webmaster@ntmwd.com",
    target: "self",
  },
];

export const NTMWDFooter = () => {
  const now = new Date();
  const year = now.getFullYear();
  return (
    <Styled.Footer role="contentinfo">
      <Container>
        <Row>
          <Col>
            <ContactInfo />
          </Col>
          <Col>
            <UsefulLinks links={footerLinks} />
          </Col>
          <Col>
            <SocialArea />
          </Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <p className="source-org copyright">
              © {year} North Texas Municipal Water District.
            </p>
          </Col>
        </Row>
      </Container>
    </Styled.Footer>
  );
};
