import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { WMKLink } from "wmk-lib";

const ContactInfo = ({
  title,
  bldg,
  street,
  pobox,
  city,
  state,
  zip,
  phone,
}) => {
  return (
    <Row className="flex-column">
      <Col>
        <strong>{title}</strong>
      </Col>
      <Col>{bldg}</Col>
      <Col>{street}</Col>
      <Col>{pobox}</Col>
      <Col>
        {city}, {state} {zip}
      </Col>
      <Col className="ci-phone">
        <WMKLink tel>{phone}</WMKLink>
      </Col>
    </Row>
  );
};

export default ContactInfo;

ContactInfo.propTypes = {
  title: PropTypes.string,
  bldg: PropTypes.string,
  street: PropTypes.string,
  pobox: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
};

ContactInfo.defaultProps = {
  title: "North Texas Municipal Water District",
  bldg: "Administration Building",
  street: "501 East Brown St.",
  pobox: "P.O. Box 2408",
  city: "Wylie",
  state: "TX",
  zip: "75098",
  phone: "972-442-5405",
};
