import React from "react";
import { StyledMainIcon } from "./MainBar";
import { FaSearch } from "react-icons/fa";

const MainSearch = () => {
  return (
    <StyledMainIcon>
      <FaSearch />
    </StyledMainIcon>
  );
};

export default MainSearch;
