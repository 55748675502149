import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Row, Col } from "react-bootstrap";
import MainDropdownItem from "./MainDropdownItem";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";

const fadeIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(10px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`;

const Styled = {};
Styled.SubMenu = styled.div`
  animation: .3s ${fadeIn} ease-in;
  //transition: all 0.3s ease;
  position: absolute;
  top: ${({ mainBar }) => mainBar.height * .7125}px;
  left: ${({ mainBar, dropSub }) =>
    dropSub
      ? mainBar.left - dropSub.left + (mainBar.width - dropSub.width) / 2
      : 0}px;
  z-index: -1;
  & > .row {
    max-width: ${({ mainBar }) => mainBar.width * 0.7}px;
    width: 100vw;
    background: #f0f0f0;
    padding: 2vh 1vh 1vh 1vh;
  }
`;
Styled.DropTitle = styled(Col)`
  padding: 0.5rem 0 0.25rem 0;
  margin: 0 0 0.5rem 0;
  border-bottom: 1px solid #cccccc;
  h6 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }
`;

const MainDropdownSub = ({ subMenu, mainBar, video }) => {
  const [dropSub, setDropSub] = useState();
  const dropRef = useRef();
  const { url } = video ? video : undefined;
  useEffect(() => {
    setDropSub(dropRef.current.getBoundingClientRect());
  }, []);
  return (
    <Styled.SubMenu mainBar={mainBar} ref={dropRef} dropSub={dropSub}>
      <Row style={{ boxShadow: "0px 12px 12px rgba(0,0,0,.3)" }}>
        {subMenu &&
          subMenu.length &&
          subMenu.map((sub, i) => {
            const { title, menu } = sub;
            return (
              <Col key={title}>
                <Row className="flex-column">
                  <Styled.DropTitle>
                    <h6>{title}</h6>
                  </Styled.DropTitle>
                  <Row className="flex-column">
                    {menu &&
                      menu.length &&
                      menu.map((m) => {
                        const { title, to, target } = m;
                        return (
                          <MainDropdownItem key={title} to={to} target={target}>
                            {title}
                          </MainDropdownItem>
                        );
                      })}
                  </Row>
                </Row>
              </Col>
            );
          })}
        {url ? (
          <Col>
            <Row className="flex-column">
              <Styled.DropTitle>
                <h6>Video</h6>
              </Styled.DropTitle>
              <Col>
                <YouTube
                  videoId={getVideoId(url).id}
                  opts={{
                    height: "128",
                    width: "228",
                  }}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Styled.SubMenu>
  );
};

export default MainDropdownSub;
