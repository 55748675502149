import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../../../vars/palette";
import MainBurgerSubMenu from "./MainBurgerSubMenu";

const StyledDropHeader = styled.h5`
  text-transform: uppercase;
  font-family: "DIN Alternate", sans-serif;
  color: ${colors.hex("primary")};
  border-bottom: 1px solid ${colors.hex("coaccent")};
  margin-top: 2vh;
`;

const MainBurgerDropMenu = ({ title, subMenu }) => {
  return (
    <Row className="flex-column" key={"drop-" + title}>
      <StyledDropHeader>{title}</StyledDropHeader>
      <Col>
        {subMenu.map((sub) => {
          const { title, menu } = sub;
          return (
            <MainBurgerSubMenu key={"sub" + title} title={title} menu={menu} />
          );
        })}
      </Col>
    </Row>
  );
};

export default MainBurgerDropMenu;
