import React, { useState, useEffect } from "react";
import TopBar from "./TopBar/TopBar";
import MainBar from "./MainBar/MainBar";

export const HeaderHybrid = () => {
  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    const resize = () => {
      setViewWidth(window.innerWidth);
    };
    resize();
    window.addEventListener("resize",resize)
    return () => window.removeEventListener("resize",resize)
  });
  return (
    <>
      <TopBar />
      <MainBar viewWidth={viewWidth} />
    </>
  );
};
