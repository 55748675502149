import React from "react";
import { FaHome } from "react-icons/fa";
import { StyledMainIcon } from "./MainBar";
import { WMKLink } from "wmk-lib";
import PropTypes from "prop-types";

const MainHome = ({ home, target }) => {
  return (
    <StyledMainIcon>
      <WMKLink to={home} target={target}>
        <FaHome />
      </WMKLink>
    </StyledMainIcon>
  );
};

export default MainHome;

MainHome.defaultProps = {
  home: "/",
};

MainHome.propTypes = {
  home: PropTypes.string,
};
