import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import MainBurgerDropMenu from "./MainBurgerDropMenu";
import { IoIosClose } from "react-icons/io";
import { colors } from "../../../../../../../vars/palette";

const StyledDrawer = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors.hover("white")};
  z-index: 10000;
  overflow: scroll;
`;

const StyledClose = styled.button`
  background: none;
  border: none;
  appearance: none;
  :hover {
    color: ${colors.hex("primary")};
  }
`;

const MainBurgerDrawer = ({ dropdowns, toggle }) => {
  return (
    <StyledDrawer fluid>
      <Row>
        <Col
          style={{
            justifyContent: "flex-end",
            display: "flex",
            padding: "3vh",
          }}
        >
          <StyledClose onClick={toggle}>
            <IoIosClose />
            Close
          </StyledClose>
        </Col>
      </Row>
      <Container>
        {dropdowns.map((drop, i) => {
          const { title, subMenu } = drop;
          //console.log(drop);
          return (
            <MainBurgerDropMenu
              key={title + i}
              title={title}
              subMenu={subMenu}
            />
          );
        })}
      </Container>
    </StyledDrawer>
  );
};

export default MainBurgerDrawer;
